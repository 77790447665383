<template>
    <div class="package-item package-item--additional">
        <h3 class="package-title">{{ name }}</h3>
        <ul class="package-item__ul">
            <li class="package-info">
                <p class="feature-desc">
                    {{ description }}
                </p>
                <p class="feature-name" v-if="locale === 'sr'">
                    {{ $t('pages.additionalPackages.duration') }} -
                    {{ duration }} dana
                </p>
                <p class="feature-name" v-else>
                    {{ duration }} {{ $t('pages.additionalPackages.duration') }}
                </p>
            </li>
        </ul>
        <footer class="package-item__footer">
            <p class="price price--additional">{{ price.value }} <span class="currency">{{ price.label }}</span></p>
            <button class="red-button"
                    @click="openModal(id)"
            >
                {{ $t('buttons.activate') }}
            </button>
        </footer>
    </div>
</template>

<script>
import Package from './Package';
export default Package;
</script>
